<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.width < 850 ? '12' : '1'">
        <v-row class="d-flex justify-center mt-7 mb-n5">
          <span>{{ $t("isConnectionActive") }}</span>
        </v-row>
        <v-row class="d-flex justify-center ml-1 mt-0 mb-n14">
          <v-tooltip
            bottom
            :disabled="
              isConnectionConfigured &&
              (isConnectionOn || canSwitchConnectionToActive)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" style="display: inline-block">
                <v-switch
                  v-model="isConnectionOn"
                  inset
                  color="success"
                  :disabled="
                    !isConnectionConfigured ||
                    (!isConnectionOn && !canSwitchConnectionToActive)
                  "
                  @change="changedActivityOfConnection"
                >
                </v-switch>
              </div>
            </template>
            <span v-if="!isConnectionOn && !canSwitchConnectionToActive">
              {{ $t("toolTips.notEnoughActiveConnections") }}
            </span>
            <span v-else>
              {{ $t("toolTips.mustBeSaved") }}
            </span>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.width < 850 ? '12' : '11'">
        <v-card class="mx-3 mb-0 pt-0 mt-6">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel key="0">
              <v-expansion-panel-header
                style="padding-left: 15px; padding-right: 15px"
                class="mt-1 mb-2 py-0"
              >
                <v-row class="d-flex justify-start align-center">
                  <v-col
                    md="1"
                    cols="1"
                    :class="$vuetify.breakpoint.width < 960 ? 'mb-n8' : ''"
                  >
                    <span class="title text-no-wrap">
                      <b>#{{ connectionBoxId }}</b>
                    </span>
                  </v-col>
                  <v-col md="3" cols="11">
                    <span
                      v-if="isConnectionConfigured"
                      class="subtitle-2 font-weight-regular"
                    >
                      <div
                        v-if="$vuetify.breakpoint.width < 960"
                        :class="
                          $vuetify.breakpoint.width < 960
                            ? 'd-flex justify-center align-center'
                            : ''
                        "
                      >
                        {{ labelSyncedFrom }}
                        &lt;--&gt;
                        {{ labelSyncedTo }}
                      </div>
                      <div v-else class="mt-0">
                        <v-row> {{ labelSyncedFrom }} </v-row>
                        <v-row class="text-no-wrap ml-4 mt-1">
                          &lt;--&gt;
                        </v-row>
                        <v-row class="mt-1"> {{ labelSyncedTo }} </v-row>
                      </div>
                    </span>
                  </v-col>
                  <v-col
                    :offset="$vuetify.breakpoint.width < 960 ? '1' : '0'"
                    md="8"
                    cols="11"
                    :class="
                      $vuetify.breakpoint.width < 960
                        ? 'd-flex justify-center align-center mb-1 mt-n2'
                        : ''
                    "
                  >
                    <span
                      v-if="isConnectionConfigured"
                      class="subtitle-2 font-weight-regular"
                    >
                      <span
                        v-if="
                          isLastSyncTimeEntriesToSHow ||
                          isLastSyncConfigObjectsToShow
                        "
                      >
                        {{ $t("lastSync") }}
                      </span>
                      <span v-if="isLastSyncTimeEntriesToSHow">
                        {{ $t("timeEntries") }}
                        <span
                          v-if="lastSyncTimeEntriesStatus === 'IN_PROGRESS'"
                        >
                          {{ $t("syncInProgress") }}
                        </span>
                        <span v-else-if="lastSyncTimeEntries !== ''">
                          {{ lastSyncTimeEntries }}
                          <span
                            v-if="isLastSyncConfigObjectsStatusUnsuccessful"
                          >
                            ({{ $t("unsuccessful") }})
                          </span>
                        </span>
                      </span>
                      <span
                        v-if="
                          isLastSyncTimeEntriesToSHow &&
                          isLastSyncConfigObjectsToShow
                        "
                      >
                        |
                      </span>
                      <span v-if="isLastSyncConfigObjectsToShow">
                        {{ $t("configurationObjects") }}
                        <span
                          v-if="lastSyncConfigObjectsStatus === 'IN_PROGRESS'"
                        >
                          {{ $t("syncInProgress") }}
                        </span>
                        <span v-else-if="lastSyncConfigObjects !== ''">
                          {{ lastSyncConfigObjects }}
                          <span
                            v-if="isLastSyncConfigObjectsStatusUnsuccessful"
                          >
                            ({{ $t("unsuccessful") }})
                          </span>
                        </span>
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mx-n2">
                <synchronization-configuration
                  :connectionBoxId="connectionBoxId"
                  :connectionBox="currentConnectionBox"
                />
                <synchronization-schedule :connectionBoxId="connectionBoxId" />
                <issue-states-selector
                  :connectionBox="currentConnectionBox"
                  v-if="
                    (currentConnectionBox.firstTool.tool === 'Jira' ||
                      currentConnectionBox.secondTool.tool === 'Jira') &&
                    (currentConnectionBox.firstTool.tool === 'Toggl Track' ||
                      currentConnectionBox.secondTool.tool === 'Toggl Track')
                  "
                />

                <v-row
                  class="mt-4 mb-1 d-inline-flex"
                  style="padding-left: 12px; padding-right: 12px"
                >
                  <v-btn
                    elevation="2"
                    color="error accent-2"
                    class="mr-2"
                    :small="$vuetify.breakpoint.width < 1460"
                    :loading="deleteButtonClick"
                    @click="
                      loader = deleteButtonClick;
                      removeThisBox();
                    "
                  >
                    {{ $t("buttons.deleteSync") }}
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                  <v-tooltip bottom :disabled="isConnectionConfigured">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          elevation="2"
                          color="orange accent-2"
                          class="mr-2"
                          :disabled="!isConnectionConfigured"
                          :class="$vuetify.breakpoint.width < 431 ? 'mt-2' : ''"
                          :small="$vuetify.breakpoint.width < 1460"
                          @click="seeMoreLogs"
                        >
                          {{ $t("buttons.seeMoreLogs") }}
                        </v-btn>
                      </div>
                    </template>
                    <span v-if="!isConnectionConfigured">
                      {{ $t("toolTips.unableToSeeLogsWhenNotConfigured") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    :disabled="
                      isSyncButtonsEnabled && !syncConfigurationObjectsRun
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          elevation="2"
                          color="light blue"
                          class="mr-2"
                          :class="$vuetify.breakpoint.width < 732 ? 'mt-2' : ''"
                          :small="$vuetify.breakpoint.width < 1460"
                          :loading="
                            syncConfigurationObjectsRun ||
                            syncConfigObjectsClick
                          "
                          :dark="isSyncButtonsEnabled"
                          :disabled="!isSyncButtonsEnabled"
                          @click="
                            loader =
                              syncConfigurationObjectsRun ||
                              syncConfigObjectsClick;
                            syncConfigurationObjects();
                          "
                        >
                          {{ $t("buttons.syncConfigurationObjects") }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </div>
                    </template>
                    <span v-if="!isConnectionConfigured">
                      {{ $t("toolTips.cannotSyncConfigurationObjectsYet") }}
                    </span>
                    <span v-else-if="syncConfigurationObjectsRun">
                      {{ $t("toolTips.syncConfigurationObjectsRun") }}
                    </span>
                    <span v-else-if="!isConnectionOn">
                      {{ $t("toolTips.cannotSyncTimeEntriesNotActive") }}
                    </span>
                    <span
                      v-else-if="
                        isCommercialVersion &&
                        $store.state.membership.immediateSyncs <= 0
                      "
                    >
                      {{ $t("toolTips.notEnoughImmediateSyncs") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    :disabled="isSyncButtonsEnabled && !syncTimeEntriesRun"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          elevation="2"
                          color="light blue"
                          :class="
                            ($vuetify.breakpoint.width > 1263 &&
                              $vuetify.breakpoint.width < 1284) ||
                            $vuetify.breakpoint.width < 1028
                              ? 'mt-2'
                              : ''
                          "
                          :small="$vuetify.breakpoint.width < 1460"
                          :loading="syncTimeEntriesRun || syncTimeEntriesClick"
                          :dark="isSyncButtonsEnabled"
                          :disabled="!isSyncButtonsEnabled"
                          @click="
                            loader = syncTimeEntriesRun || syncTimeEntriesClick;
                            syncTimeEntries();
                          "
                        >
                          {{ $t("buttons.syncTimeEntries") }}
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </div>
                    </template>
                    <span v-if="!isConnectionConfigured">
                      {{ $t("toolTips.cannotSyncConfigurationObjectsYet") }}
                    </span>
                    <span v-else-if="syncTimeEntriesRun">
                      {{ $t("toolTips.syncTimeEntriesRun") }}
                    </span>
                    <span v-else-if="!isConnectionOn">
                      {{ $t("toolTips.cannotSyncTimeEntriesNotActive") }}
                    </span>
                    <span
                      v-else-if="
                        isCommercialVersion &&
                        $store.state.membership.immediateSyncs <= 0
                      "
                    >
                      {{ $t("toolTips.notEnoughImmediateSyncs") }}
                    </span>
                  </v-tooltip>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-snackbar
            right
            top
            app
            color="success"
            v-model="syncConfigObjectsSuccessful"
          >
            {{ $t("notificationSnackbars.syncConfigurationObjectsProcessed") }}
            #{{ connectionBoxId }}
            {{ $t("notificationSnackbars.areBeingSynchronized") }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="syncConfigObjectsSuccessful = false"
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            right
            top
            app
            color="error"
            v-model="syncConfigObjectsError"
          >
            {{ $t("notificationSnackbars.syncConfigurationObjectsProcessed") }}
            #{{ connectionBoxId }}
            {{ $t("notificationSnackbars.areNotBeingSynchronized") }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="syncConfigObjectsError = false"
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>

          <v-snackbar
            right
            top
            app
            color="success"
            v-model="syncTimeEntriesSuccessful"
          >
            {{ $t("notificationSnackbars.syncTimeEntriesProcessed") }}
            #{{ connectionBoxId }}
            {{ $t("notificationSnackbars.areBeingSynchronized") }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="syncTimeEntriesSuccessful = false"
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            right
            top
            app
            color="error"
            v-model="syncTimeEntriesError"
          >
            {{ $t("notificationSnackbars.syncTimeEntriesProcessed") }}
            #{{ connectionBoxId }}
            {{ $t("notificationSnackbars.areNotBeingSynchronized") }}
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="syncTimeEntriesError = false">
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>

          <v-snackbar
            right
            top
            app
            color="success"
            v-model="changeActivitySuccessful"
          >
            {{ $t("notificationSnackbars.connectionBox") }}
            #{{ connectionBoxId }}
            <span v-if="isConnectionOn">
              {{ $t("notificationSnackbars.wasSuccessfullyActivated") }}
            </span>
            <span v-else>
              {{ $t("notificationSnackbars.wasSuccessfullyDeactivated") }}
            </span>
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="changeActivitySuccessful = false"
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar right top app color="error" v-model="changeActivityError">
            {{ $t("notificationSnackbars.connectionBox") }}
            #{{ connectionBoxId }}
            <span v-if="isConnectionOn">
              {{ $t("notificationSnackbars.wasUnsuccessfullyDeactivated") }}
            </span>
            <span v-else>
              {{ $t("notificationSnackbars.wasUnsuccessfullyActivated") }}
            </span>
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="changeActivityError = false">
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            right
            top
            app
            color="error"
            v-model="removedConnectionBoxError"
          >
            {{ $t("notificationSnackbars.connectionBox") }}
            #{{ connectionBoxId }}
            {{ $t("notificationSnackbars.wasNotSuccessfullyRemoved") }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="removedConnectionBoxError = false"
              >
                {{ $t("buttons.close") }}
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import { ToolType } from "../../enums/tools/typeOfTool";
import { getTimeFormatOptions } from "./shared";
import {
  deleteConnection,
  patchConnection,
  syncConfigObjects,
  syncTimeEntries,
} from "../../services/backendService";
import { Constants } from "../../constants/Constants";
import IssueStatesSelector from "./issueTypes/IssueStatesSelector.vue";

export default {
  components: {
    "synchronization-configuration": () =>
      import("./configuration/SynchronizationConfiguration.vue"),
    "synchronization-schedule": () =>
      import("./schedule/SynchronizationSchedule.vue"),
    "issue-states-selector": IssueStatesSelector,
  },
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,

      isConnectionOn: false,
      panel: [],

      currentConnectionBox: null,
      loader: null,
      labelSyncedFrom: "",
      labelSyncedTo: "",
      lastSyncTimeEntries: "",
      lastSyncTimeEntriesStatus: "",
      lastSyncConfigObjects: "",
      lastSyncConfigObjectsStatus: "",

      syncTimeEntriesClick: false,
      syncConfigObjectsClick: false,
      deleteButtonClick: false,

      changeActivitySuccessful: false,
      changeActivityError: false,
      syncTimeEntriesSuccessful: false,
      syncTimeEntriesError: false,
      syncConfigObjectsSuccessful: false,
      syncConfigObjectsError: false,
      removedConnectionBoxError: false,
    };
  },
  computed: {
    canSwitchConnectionToActive() {
      return (
        !this.isCommercialVersion ||
        this.$store.getters.canSwitchConnectionToActive
      );
    },
    isLastSyncTimeEntriesStatusUnsuccessful() {
      return this.lastSyncTimeEntriesStatus === "ERROR";
    },
    isLastSyncConfigObjectsStatusUnsuccessful() {
      return this.lastSyncConfigObjectsStatus === "ERROR";
    },
    isConnectionConfigured() {
      return !this.connectionBoxId
        .toString()
        .startsWith(this.$t("connectionBoxIdNew"));
    },
    isSyncButtonsEnabled() {
      return (
        !this.connectionBoxId
          .toString()
          .startsWith(this.$t("connectionBoxIdNew")) &&
        this.isConnectionOn &&
        (!this.isCommercialVersion ||
          this.$store.state.membership.immediateSyncs > 0)
      );
    },
    isLastSyncTimeEntriesToSHow() {
      return (
        this.lastSyncTimeEntries !== "" ||
        this.lastSyncTimeEntriesStatus === "IN_PROGRESS"
      );
    },
    isLastSyncConfigObjectsToShow() {
      return (
        this.lastSyncConfigObjects !== "" ||
        this.lastSyncConfigObjectsStatus === "IN_PROGRESS"
      );
    },
    syncTimeEntriesRun() {
      return this.lastSyncTimeEntriesStatus === "IN_PROGRESS";
    },
    syncConfigurationObjectsRun() {
      return this.lastSyncConfigObjectsStatus === "IN_PROGRESS";
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  created() {
    this.fetchConnectionBox();
  },
  mounted() {
    EventBus.$on("connection-box-open", (calledId) => {
      if (calledId === this.connectionBoxId) {
        this.panel = [0];
        setTimeout(() => {
          EventBus.$emit("configuration-box-open", calledId);
        }, 60);
      }
    });
    EventBus.$on("connection-box-saved", (oldId, newId) => {
      if (oldId === this.connectionBoxId) {
        this.connectionBoxId = newId;
        this.fetchConnectionBox();
      }
    });
  },
  props: ["connectionBoxId", "connectionState"],
  methods: {
    fetchConnectionBox() {
      this.fetchCurrentConnectionBox();
      this.setLabels();
      this.lastSyncTimeAndProjects();
      this.isConnectionOn = this.currentConnectionBox.isActive;
    },
    async removeThisBox() {
      this.deleteButtonClick = true;
      if (
        !this.connectionBoxId
          .toString()
          .startsWith(this.$t("connectionBoxIdNew"))
      ) {
        const response = await deleteConnection(
          this.currentConnectionBox.databaseId
        );
        if (!response) {
          this.removedConnectionBoxError = true;
        } else {
          this.$emit("remove-this-box", this.connectionBoxId);
        }
      } else {
        this.$emit("remove-this-box", this.connectionBoxId);
      }
      this.deleteButtonClick = false;
    },
    async syncConfigurationObjects() {
      this.syncConfigObjectsClick = true;
      const response = await syncConfigObjects(
        this.currentConnectionBox.databaseId
      );
      if (!response) {
        this.syncConfigObjectsError = true;
      } else {
        this.lastSyncConfigObjectsStatus = "IN_PROGRESS";
        this.syncConfigObjectsSuccessful = true;
        this.$store.dispatch("useImmediateSync");
      }
      this.syncConfigObjectsClick = false;
    },
    async syncTimeEntries() {
      this.syncTimeEntriesClick = true;
      const response = await syncTimeEntries(
        this.currentConnectionBox.databaseId
      );
      if (!response) {
        this.syncTimeEntriesError = true;
      } else {
        this.lastSyncTimeEntriesStatus = "IN_PROGRESS";
        this.syncTimeEntriesSuccessful = true;
        this.$store.dispatch("useImmediateSync");
      }
      this.syncTimeEntriesClick = false;
    },
    setLabels() {
      this.setFirstLabel();
      this.setSecondLabel();
    },
    setFirstLabel() {
      this.labelSyncedFrom = this.getLabelToBoxHeading(
        this.currentConnectionBox.firstTool
      );
    },
    setSecondLabel() {
      this.labelSyncedTo = this.getLabelToBoxHeading(
        this.currentConnectionBox.secondTool
      );
    },
    getLabelToBoxHeading(toolObject) {
      if (toolObject.tool === ToolType.TOGGL_TRACK.name) {
        return toolObject.selectedTogglTrackWorkspaceName;
      } else if (toolObject.tool === ToolType.REDMINE.name) {
        return toolObject.redmineApiPoint;
      } else if (toolObject.tool === ToolType.JIRA.name) {
        return toolObject.jiraDomain;
      }
    },
    lastSyncTimeAndProjects() {
      this.lastSyncedTimeEntries();
      this.lastSyncedConfigObjects();
    },
    lastSyncedTimeEntries() {
      this.lastSyncTimeEntries = this.getFormattedDate(
        this.currentConnectionBox.schedule.timeEntries.lastJobTime
      );
      this.lastSyncTimeEntriesStatus =
        this.currentConnectionBox.schedule.timeEntries.status;
    },
    lastSyncedConfigObjects() {
      this.lastSyncConfigObjects = this.getFormattedDate(
        this.currentConnectionBox.schedule.configObjects.lastJobTime
      );
      this.lastSyncConfigObjectsStatus =
        this.currentConnectionBox.schedule.configObjects.status;
    },
    getFormattedDate(unixTimestamp) {
      if (!unixTimestamp) {
        return "";
      }

      const date = new Date(unixTimestamp);
      return date.toLocaleString(
        undefined,
        getTimeFormatOptions(this.$store.state.user.timeZone)
      );
    },

    seeMoreLogs() {
      this.$router.push({
        name: "Logs-JobLogs",
        params: { connectionBoxId: this.connectionBoxId },
      });
    },

    // Connection box methods
    fetchCurrentConnectionBox() {
      this.currentConnectionBox = this.getCurrentConnectionBox();
    },

    getCurrentConnectionBox() {
      let connectionBox = null;
      for (let i = 0; i < this.$store.state.userConnectionBoxes.length; ++i) {
        if (
          this.connectionBoxId ===
          this.$store.state.userConnectionBoxes[i].connectionBoxId
        ) {
          connectionBox = this.$store.state.userConnectionBoxes[i];
          break;
        }
      }
      return connectionBox;
    },

    async changedActivityOfConnection() {
      const bodyToSend = {
        isActive: this.isConnectionOn,
      };
      const response = await patchConnection(
        this.currentConnectionBox.databaseId,
        bodyToSend
      );
      if (!response) {
        this.isConnectionOn = !this.isConnectionOn;
        this.changeActivityError = true;
      } else {
        this.changeActivitySuccessful = true;
        this.dispatchCurrentConnectionBox();
      }
    },

    dispatchCurrentConnectionBox() {
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let pos = 0; pos < connectionBoxes.length; ++pos) {
        if (connectionBoxes[pos].connectionBoxId === this.connectionBoxId) {
          connectionBoxes[pos].isActive = this.isConnectionOn;
          break;
        }
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
