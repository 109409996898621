<template>
  <v-card class="mx-auto mb-0 pt-0 mt-0">
    <v-expansion-panels>
      <v-expansion-panel key="0">
        <v-expansion-panel-header>
          {{ $t("stateSelect.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <issue-state-for-tool-selector
            :tool="connectionBox.firstTool"
            :connectionBoxId="connectionBox.connectionBoxId"
            :ConnectionBoxDBId="connectionBox.databaseId"
            serviceNumber="1"
          >
          </issue-state-for-tool-selector>

          <issue-state-for-tool-selector
            :tool="connectionBox.secondTool"
            :connectionBoxId="connectionBox.connectionBoxId"
            :ConnectionBoxDBId="connectionBox.databaseId"
            serviceNumber="2"
          >
          </issue-state-for-tool-selector>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import IssueStateForToolSelector from "./IssueStateForToolSelector.vue";

export default {
  name: "IssueTypeSelector",
  components: {
    "issue-state-for-tool-selector": IssueStateForToolSelector,
  },
  data() {
    return {};
  },
  props: ["connectionBox"],
};
</script>