<template>
  <v-card outlined>
    <v-subheader> {{ serviceNumber }}. {{ tool.tool }}</v-subheader>
    <div v-if="tool.tool === 'Jira'" class="mx-3">
      <v-row no-gutters>
        <v-chip-group
          column
          :multiple="true"
          active-class="light-green lighten-3"
          v-model="selectedIssueStatesIndexes"
          class="mt-2"
          @change="selectionOfTheIssueStatesChanged"
        >
          <v-chip v-for="issueState in allIssueStates" :key="issueState.id">
            {{ issueState.name }}
          </v-chip>
        </v-chip-group>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
          </template>
          <span> {{ $t("toolTips.selectIssueStatesToSync") }} </span>
        </v-tooltip>
      </v-row>
    </div>
    <v-snackbar right top app color="success" v-model="statesSaved">
      {{ $t("stateSelect.snackbarMessage") }} {{ connectionBoxId }}
      {{ $t("notificationSnackbars.savedUpdated") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="statesSaved = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="statesSaveingFailed">
      {{ $t("stateSelect.snackbarMessage") }} {{ connectionBoxId }}
      {{ $t("notificationSnackbars.notSuccessful") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="statesSaveingFailed = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="tool.tool === 'Toggl Track'" class="mx-3">
      <p>{{ $t("stateSelect.togglTrackMessage") }}</p>
    </div>
    <div v-if="tool.tool === 'Redmine'" class="mx-3">
      <p>{{ $t("stateSelect.redmineMessage") }}</p>
    </div>
  </v-card>
</template>


<script>
import axios from "axios";
import { Constants } from "../../../constants/Constants";
import { patchConnection } from "../../../services/backendService";
export default {
  props: ["connectionBoxId", "tool", "ConnectionBoxDBId", "serviceNumber"],

  data() {
    return {
      allIssueStates: [],
      ignoredStates: [],
      selectedIssueStatesIndexes: [],

      debounceTimer: null,
      debounceDelay: 600, // ms
      statesSaveingFailed: false,
      statesSaved: false,
    };
  },
  methods: {
    selectionOfTheIssueStatesChanged() {
      const newIgnored = [];

      for (let i = 0; i < this.allIssueStates.length; i++) {
        let found = false;
        for (let j = 0; j < this.selectedIssueStatesIndexes.length; j++) {
          if (this.selectedIssueStatesIndexes[j] == i) {
            found = true;
            break;
          }
        }
        if (!found) {
          newIgnored.push(JSON.parse(JSON.stringify(this.allIssueStates[i])));
        }
      }
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        const response = await this._sendNewIgnoredToAPI(newIgnored);
        if (response) {
          this.ignoredStates = response.secondService.config.ignoredIssueStates;
          this.tool.jiraIgnoredIssueStates =
            response.secondService.config.ignoredIssueStates;
        }
      }, this.debounceDelay).then(() => {
        this._calculateSelectedIndexes();
      });
    },

    async fetchAllIssueStates() {
      //not supported for others yeselse
      if (this.tool.tool === "Jira") {
        this.allIssueStates = await this._fetchJiraIssueStates();
        this.ignoredStates = this.tool.jiraIgnoredIssueStates;
        this._calculateSelectedIndexes();
      }
    },

    async _fetchJiraIssueStates() {
      const accessToken = await this.$auth.getTokenSilently();
      const data = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          api_key: this.tool.jiraApiKey,
          domain: this.tool.jiraDomain,
          user_email: this.tool.jiraUserEmail,
        },
      };
      let responseData = [];
      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/jira_issue_statuses`,
          data
        )
        .then((response) => {
          responseData = response.data;
        })
        .catch((e) => {});
      return responseData;
    },

    _calculateSelectedIndexes() {
      this.selectedIssueStatesIndexes = [];
      for (let i = 0; i < this.allIssueStates.length; i++) {
        let found = false;
        for (let j = 0; j < this.ignoredStates.length; j++) {
          if (this.ignoredStates[j].id === this.allIssueStates[i].id) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.selectedIssueStatesIndexes.push(i);
        }
      }
    },
    async _sendNewIgnoredToAPI(newIgnored) {
      let data;
      if (this.tool.tool === "Jira") {
        this.tool.jiraIgnoredIssueStates = newIgnored;
        data = {
          ignoredIssueStates: newIgnored,
          serviceNumber: this.serviceNumber,
        };
      } else return;

      const response = await patchConnection(this.ConnectionBoxDBId, data);
      if (!response) {
        this.statesSavingFailed = true;
      } else {
        this.statesSaved = true;
      }
      return response;
    },
  },

  async created() {
    await this.fetchAllIssueStates();
  },
};
</script>